import React from 'react';

import Layout from '../../components/layout/layout';
import LeaguesListing from '../../components/leagues/leagues-listing';
import SEO from '../../components/seo';

const LeaguesPage = () => {
  return (
    <Layout>
      <SEO title="Leagues" />
      <LeaguesListing />
    </Layout>
  );
};
export default LeaguesPage;
