import React, { FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { MoreFilters } from '@clubspark-react/ui';

import MoreFiltersSectionDistrict from '../more-filters/more-filters-section-district';

type LeaguesMoreFiltersProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onSubmit: (section: string | undefined, district: string | undefined) => void;
  initialSection?: string;
  initialDistrict?: string;
};

const LeaguesMoreFilters: FC<LeaguesMoreFiltersProps> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  initialDistrict,
  initialSection,
}) => {
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState<string | undefined>(initialSection);
  const [selectedDistrict, setSelectedDistrict] = useState<string | undefined>(initialDistrict);

  const handleSectionChange = useCallback((value) => {
    setSelectedSection(value);
    setSelectedDistrict(undefined);
  }, []);

  const handleFiltersReset = () => {
    setSelectedDistrict(undefined);
    setSelectedSection(undefined);
  };

  const handleSubmit = () => {
    onSubmit(selectedSection, selectedDistrict);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedSection(initialSection);
    setSelectedDistrict(initialDistrict);
  }, [initialDistrict, initialSection]);

  return (
    <MoreFilters
      fullWidth={false}
      applyBtnText={t('apply filters')}
      open={isOpen}
      onOpenChange={() => {
        setSelectedDistrict(initialDistrict);
        setSelectedSection(initialSection);
      }}
      title={t('more filters')}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      clearBtnText={t('clear all filters')}
      onApplyBtnClick={handleSubmit}
      onClearBtnClick={handleFiltersReset}
    >
      <MoreFiltersSectionDistrict
        selectedSection={selectedSection}
        setSelectedSection={handleSectionChange}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        includeHighSchoolStates={true}
      />
    </MoreFilters>
  );
};

export default LeaguesMoreFilters;
