import React, { FC } from 'react';

import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import AdminTable, { DataCols } from 'src/components/admin-table/admin-table';
import { LeaguesStatus } from 'src/components/areas/area-helpers';
import { GetAreaLeagues_leagues_items } from 'src/graphql-types/GetAreaLeagues';

import { ControlProps } from '@clubspark-react/clubspark-react-tools';
import { Avatar } from '@clubspark-react/ui';

type LeaguesTableProps = {
  leagues: GetAreaLeagues_leagues_items[] | undefined;
  controlProps: ControlProps;
  loading: boolean;
};
const LeaguesTable: FC<LeaguesTableProps> = ({ controlProps, loading, leagues }) => {
  const { t } = useTranslation();

  const isSameYear = (startDate: string, endDate: string) => {
    return moment(startDate).year() === moment(endDate).year();
  };

  const columns: DataCols<GetAreaLeagues_leagues_items> = [
    {
      key: 'league',
      getValue: (item) => (
        <Grid container spacing={2} alignItems={'flex-end'}>
          <Grid item>
            <Avatar radius={'none'} alt={item?.name} src={item?.logoUrl ?? ''} />
          </Grid>
          <Grid item>
            <p>{item.name ?? '-'}</p>
            <span>{item.id ?? '-'}</span>
          </Grid>
        </Grid>
      ),
      title: t('league'),
    },
    {
      key: 'dates',
      getValue: (item) =>
        isSameYear(item.startDate, item.endDate)
          ? t('league dates same year', {
              start: moment(item?.startDate),
              end: moment(item?.endDate),
            })
          : t('league dates diff year', {
              start: moment(item?.startDate),
              end: moment(item?.endDate),
            }),
      title: t('dates'),
    },
    {
      key: 'category',
      getValue: (item) => item?.level?.levelGroup?.name ?? '-',
      title: t('category'),
    },
    {
      key: 'leagueCoordinator',
      getValue: (item) => item?.primaryAdministrator?.displayName ?? '-',
      title: t('league coordinator'),
    },
    {
      key: 'approved',
      getValue: (item) => <LeaguesStatus status={item?.status} />,
      title: t('approved'),
    },
  ];
  return (
    <AdminTable hideTopPaginationInfo data={leagues} loading={loading} columns={columns} controls={controlProps} />
  );
};

export default LeaguesTable;
